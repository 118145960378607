import { MasterConfig } from 'ui-shared';

export const master = {
  titulo: 'Gerenciador Consulta',
  rota: '/home',
  imgUrl: '../assets/AF_ICONE_BLUE_ROUTE_WHITE.png',
  esconderContaEmUso: true,
  links: [
    { titulo: 'Home', icone: 'home', label: 'Home', rota: '/home' },
    { titulo: 'Clientes', icone: 'contacts', label: 'Clientes', rota: '/clientes' }
  ]
} as MasterConfig;
