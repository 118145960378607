import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {LoginService} from 'ui-shared';


@Component({
  template: '<span>Carregando ...</span>'
})
export class LoginComponent implements OnInit {

  constructor(
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(Router) private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LoginService) private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    const { accessToken, logout } = this.trataQueryString(this.route.snapshot);
    if (accessToken) {
      this.loginService.salvarToken(accessToken);
      this.router.navigateByUrl('/home');
    } else if (this.loginService.estaLogado()) {
      this.router.navigateByUrl('/home');
    }
    else {
      const params = `?logout=${logout || false}&aplicacao=gerenciador-consulta&redirect_uri=${window.location.origin}/login`;
      this.document.location.href = `${environment.identidade.login}${params}`;
    }
  }

  trataQueryString(snapshot: ActivatedRouteSnapshot) {

    let retorno = { accessToken: null, logout: null };

    if (!snapshot) {
      return retorno;
    }

    if (snapshot.fragment) {
      retorno = snapshot.fragment.split('&')?.reduce((result: any, queryParam) => {
        const a = queryParam.split('=');
        const param = a[0];
        const value = a[1];
        if (param === 'access_token') {
          result.accessToken = value;
        } else {
          result[param] = value;
        }
        return result;
      }, {});
    }

    Object.assign(retorno, snapshot.queryParams);

    return retorno;
  }
}
