export const environment = {
  production: false,
  identidade: {
    clientId: 'identidade',
    apiUrl: 'https://identidadeapi-dev.blueroute.com.br/api',
    login: 'https://identidade-dev.blueroute.com.br/login'
  },
  gerenciadorConsulta: {
    clientId: 'gc-conf',
    apiUrl: 'https://gerconapi-dev.azurewebsites.net/api',
  }
};

