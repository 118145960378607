import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MasterComponent} from "ui-shared";
import {GerenciadorConsultaGuard} from "./services/gerenciador-consulta-guard.service";
import {AcessoNegadoComponent} from "./acesso-negado/acesso-negado.component";
import {LoginComponent} from "./login/login.component";

const routes: Routes = [
  { path: 'acesso-negado', component: AcessoNegadoComponent },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: '', component: MasterComponent, canActivate: [GerenciadorConsultaGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module')
          .then(m => m.HomeModule)
      },
      {
        path: 'clientes',
        loadChildren: () => import('./clientes/clientes.module')
          .then(m => m.ClientesModule)
      },
      { path: '**', redirectTo: 'home' },
    ]
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
